import React, { useState, useEffect, useRef } from "react";
import * as PR from "primereact"
import { DummyData } from "../../../data/DummyData";
import Search from "../../../components/Search";
import useEmployee from "./hook";
import { useStateValue } from "../../../context";
import { _setObject } from "../../app/action";

const EmployeeList = () => {
    const toast = useRef(null);
    const [{ app }, dispatch] = useStateValue()
    const { newEmployee, Employee_list, employee, updatedEmployee } = app || {}
    const [{ createEmployee, getEmployeelist, getEmployee, updateEmployee, setVisibleRight, ApiCall, setVisible }, { visibleRight, visible }] = useEmployee()
    const initialvalues = { employeeID: null, firstName: '', LastName: '', Email: '', status: true }
    const [initialState, setInitialState] = useState(initialvalues)
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [dialog, setDialog] = useState(false);
    const [empData, setEmpData] = useState(null);

    useEffect(() => {
        getEmployeelist();
    }, []);

    useEffect(() => {
        if (employee) {
            setVisibleRight(true);
            setInitialState({ ...initialState, employeeID: employee?.emp_id, firstName: employee?.firstname, LastName: employee?.lastname, Email: employee?.emailid })
        }
    }, [employee]);


    const onSubmit = (e) => {
        e.preventDefault();
        if (employee) {
            updateEmployee(employee?.id, initialState)
        } else {
            createEmployee(initialState);
        }
    };

   const handleApiCall = (empData) => {
        const {e,rowData} = empData
            setDialog(false);
            ApiCall(e,rowData);
    }
    
   const footer = (
        <div>
            <PR.Button label="Yes" icon="pi pi-check" onClick={() => handleApiCall(empData)} autoFocus />
            <PR.Button label="No" icon="pi pi-times" onClick={() => setDialog(false)} className="p-button-text" />
        </div>
    );
	    
    useEffect(() => {
        if (newEmployee) {
            toast.current.show({ severity: newEmployee.success ? "success": "info" , summary: newEmployee.message, detail:newEmployee.detail });
            dispatch(_setObject({ newEmployee: undefined }))
        }
        if (updatedEmployee) {
            toast.current.show({ severity: updatedEmployee.success ? "success": "info" , detail: updatedEmployee.success ? updatedEmployee.message : "Unauthorized Email Domain" });
            dispatch(_setObject({ updatedEmployee: undefined }))
        }
    }, [newEmployee, updatedEmployee])


    const Header = (
        <div className="grid grid-nogutter">
            <Search setGlobalFilter={setGlobalFilter} globalFilter={globalFilter} />
            <PR.Button className="btn-accent h-3rem w-full md:w-15rem" label="Add Employee" onClick={() => {
                setVisibleRight(true)
                setInitialState(initialvalues)
                dispatch(_setObject({ employee: undefined }))
            }} />
        </div>

    );
    const _onRowDblClick = (e) => {
        let { data = null } = e
        getEmployee(data.id);
    };
    const columns = [
        { field: 'emp_id', header: 'Employee ID', style: { height: "40px", width: '200px' } /*frozen className="font-bold"*/ },
        { field: 'firstname', header: 'First name', style: { height: "40px" } },
        { field: 'lastname', header: 'Last name', style: { height: "40px" } },
        { field: 'emailid', header: 'Email', style: { height: "40px" } },
        {
            field: 'status', header: 'Deactivate', style: { height: "40px" }, body: (rowData) => {
                // if (rowData.status === true) {
                return (<>
                    <PR.InputSwitch checked={rowData.status} onChange={(e) => {setEmpData({e, rowData});  setDialog(true)}} />
                    <PR.Dialog header="Are You Sure?" visible={dialog} style={{ width: '50vw' }} onHide={() => setDialog(false)} footer={footer}/>
                </>)
                

            }
        },
      ];

	return (
        <>
            <PR.Sidebar visible={visibleRight} position="right" className="employee-p-sidebar" onHide={() => { setVisibleRight(false); dispatch(_setObject({ employee: undefined })) }}>
                <h2>Employee</h2>
                <div className="flex align-items-center gap-2 w-full">
                    <div className="field p-fluid w-full">
                        <label>Employee ID</label>
                        <PR.InputText
                            type="number"
                            value={initialState.employeeID}
                            onChange={(e) => setInitialState({ ...initialState, employeeID: e.target.value })}
                            placeholder="Enter Employee ID"
                        />
                    </div>
                </div>
                <div className="grid grid-nogutter ">
                    <div className="col-12 md:col lg:pr-2">
                        <div className="flex align-items-center gap-2 w-full">
                            <div className="field p-fluid w-full">
                                <label>FirstName</label>
                                <PR.InputText
                                    type="text"
                                    value={initialState.firstName}
                                    onChange={(e) => setInitialState({ ...initialState, firstName: e.target.value })}
                                    placeholder="Enter FirstName"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col lg:pl-2">
                        <div className="flex align-items-center gap-2 w-full">
                            <div className="field p-fluid w-full">
                                <label>LastName</label>
                                <PR.InputText
                                    type="text"
                                    value={initialState.LastName}
                                    onChange={(e) => setInitialState({ ...initialState, LastName: e.target.value })}
                                    placeholder="Enter LastName"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex align-items-center gap-2 w-full">
                    <div className="field p-fluid w-full">
                        <label>Email</label>
                        <PR.InputText
                            type="text"
                            value={initialState.Email}
                            onChange={(e) => setInitialState({ ...initialState, Email: e.target.value })}
                            placeholder="Enter Email"
                        />
                    </div>
                </div>
                <div className="absolute bottom-0 right-0 w-full px-4 py-3 bg-white border-top-1 border-400">
                    <div className='flex justify-content-end'>
                        <div className='flex gap-2'>
                            <PR.Button
                                type="button"
                                label="Submit"
                                disabled={!initialState.employeeID || initialState.firstName === "" || initialState.LastName === "" || initialState.Email === "" }
                                onClick={(e) => { onSubmit(e) }}
                            />
                        </div>
                    </div>
                </div>
            </PR.Sidebar>


            <div className="card pt-0 mt-0 shadow-2 fadein animation-duration-300" >
                <PR.Toast ref={toast} />
                <PR.DataTable value={Employee_list} header={Header} size="small"
                    paginator rows={25}
                    rowsPerPageOptions={[10, 25, 50]}
                    scrollable
                    scrollHeight="700px"
                    className="mt-4"
                    selectionMode="single"
                    selection={selectedEmployee}
                    onSelectionChange={(e) =>  setSelectedEmployee(e.value) }
                    dataKey="id"
                    globalFilter={globalFilter}
                    // onRowSelect={OnRowSelect}
                    onRowDoubleClick={_onRowDblClick}
                    metaKeySelection={false}
                    tableStyle={{ minWidth: "50rem", height: "100px" }}>

                    {/* <PR.Column field="emp_id" header="Employee ID" style={{ height: "40px", width: '200px' }} frozen className="font-bold"></PR.Column>
                    <PR.Column field="firstname" header="First name" style={{ height: "40px" }}></PR.Column>
                    <PR.Column field="lastname" header="Last name" style={{ height: "40px" }}></PR.Column>
                    <PR.Column field="emailid" header="Email" style={{ height: "40px" }}></PR.Column>
                    <PR.Column field="status" header="Deactivate" body={actionTemplate} style={{ height: "40px", width: '100px', textAlign: 'center' }}></PR.Column> */}
                    {columns.map((column) => (
                        <PR.Column
                            key={column.field}
                            field={column.field}
                            header={column.header}
                            body={column.body}
                        // headerStyle={column.headerStyle}

                        // body={column.field === 'actions' ? renderActions : undefined}
                        />

                    ))}
                </PR.DataTable>
            </div>
	 {visible && <div className="spinner"><PR.ProgressSpinner aria-label="Loading" /></div>}
        </>
    );
}

export default EmployeeList;
