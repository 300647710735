import axios from 'axios';

const PORT = process.env.REACT_APP_PORT
const BASE_URL = `https://${window.location.hostname}/relay`
console.log("BASE_URL",BASE_URL);


const relayService = ({ url, method = 'GET', headers, data }) => {
    console.log('axios');
    console.log("BASE_URL",BASE_URL)
    const axios_instance = axios({ url, method, baseURL: BASE_URL, headers, data })
    console.log("axios_instance",axios_instance)
    return axios_instance
}

export default relayService

