import React, { useState, useEffect, useRef } from "react";
import * as PR from 'primereact';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const toast = useRef(null);
    const navigate = useNavigate()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [pin, setPin] = useState('')
    const [Toast, setToast] = useState(false)
    // const Username = "Sameer";
    // const Password = "Sam@123";
    const Username = "romel.almarinez@microsourcing.com";
    const Password = ">;Dz&y8:2X.H2V6wXGM)`3hDj?285K";
    const PIN = "030703";

    useEffect(() => {
        if (Toast) {
            toast.current.show({ severity: "info", detail: "Incorrect Username or Password" });
            setToast(false);
        }
    }, [Toast]);


    return (
        <>
            <PR.Toast ref={toast} />
            <div className='login'>
                <div className="card flex justify-content-center align-items-center w-30rem h-30rem shadow-2 border-round">
                    <div className="flex flex-column w-full px-5">
                        <div className="flex align-items-center gap-2 w-full">
                            <div className="field p-fluid w-full">
                                <label>Username</label>
                                <PR.InputText
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    placeholder="Enter Username"
                                />
                            </div>
                        </div>
                        <div className="flex align-items-center gap-2 w-full">
                            <div className="field p-fluid w-full">
                                <label>Password</label>
                                <PR.InputText
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Enter Password"
                                />
                            </div>
                        </div>
                        <div className="flex align-items-center gap-2 w-full">
                            <div className="field p-fluid w-full">
                                <label>PIN</label>
                                <PR.InputText
                                    type="text"
                                    value={pin}
                                    onChange={(e) => setPin(e.target.value)}
                                    placeholder="Enter PIN"
                                />
                            </div>
                        </div>
                        <div className="flex justify-content-end">
                            <PR.Button
                                label="Login"
                                id="btn"
                                className="w-full"
                                onClick={() => {
                                    if (username === Username && password === Password && pin === PIN) {
                                        navigate("/employee/employeelist")
                                    } else {
                                        setToast(true);
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}

export default Login;
