export const DummyData = {
    getData() {
        return [
            {
                id: '30100',
                name: 'Small Private',
                status: 'Active',
            },
            {
                id: '30101',
                name: 'Semi Private',
                status: 'Active',
            },
            {
                id: '30102',
                name: 'Regular Private',
                status: 'Inactive',
            },
        ]
    },
    getAllData() {
        return Promise.resolve(this.getData());
    },
}