import React, { useState } from 'react';
import { links as employeeLinks } from "../data/SidebarData";
import { NavLink, useNavigate } from "react-router-dom";
import * as PR from "primereact"
const Aside = ({ visibleToggle }) => {
    const navigate = useNavigate()
    let links;
    const currentPathname = window.location.pathname;

    if (currentPathname.startsWith("/employee")) {
        links = employeeLinks;
    }

    const toggleDropdown = (index) => {
        if (index === 0) {
            // Toggle the dropdown state of "Home" dropdown
            setDropdownVisible((prevState) => ({
                ...prevState,
                [index]: true
            }));
        } else {
            // Toggle the dropdown state of other dropdowns
            setDropdownVisible((prevState) => ({
                ...prevState,
                [index]: !prevState[index]
            }));
        }
    };

    const [dropdownVisible, setDropdownVisible] = useState({
        0: true // Set first index to true for "Home" dropdown
    });

    const handleLinkClick = (index) => {
        localStorage.setItem("activeLinkIndex", index);
        visibleToggle();
    };

    return (
        <>
            <h3>CoverGenius</h3>
            {links && links.length > 0 ? (
                links.map((item, index) => (
                    <div key={item.title}>
                        <p
                            className="text-gray-900 font-bold uppercase cursor-pointer flex justify-content-between"
                            onClick={() => toggleDropdown(index)}
                        >
                            {item.title}
                            <span className="mr-2">{item.icon}</span>
                        </p>
                        {dropdownVisible[index] && (
                            <div className="dropdown-menu">
                                {item.links.map((link) => (
                                    <NavLink
                                        to={`/${link.path}`}
                                        key={link.name}
                                        onClick={() => handleLinkClick(index)}
                                        className={({ isActive }) =>
                                            isActive ? "active-link" : "normal-link"
                                        }
                                    >
                                        <div className="my-icons shadow-2">{link.icon}</div>
                                        <span className="capitalize">{link.name}</span>
                                    </NavLink>
                                ))}
                            </div>
                        )}
                    </div>
                ))
            ) : (
                <p>No links available.</p>
            )}
        </>
    );
}

export default Aside;
