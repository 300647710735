import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const ShowAsideNav = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    // const [showSideAndNav, setShowSideAndNav] = useState(false)
    // console.log(logged);
    return (
        <div>
            {children}
        </div>
    );
}

export default ShowAsideNav;
