import logger from '../../utils/logger'
export const INITIAL_STATE = {
    accessToken: localStorage.getItem("kc_access_token")
};

export default (state = INITIAL_STATE, { type, payload }) => {
    logger.info("REDUCER", type, payload);
    switch (type) {
        case 'SET_OBJECT':
            return {
                ...state,
                ...payload
            }
        default:
            return state;
    }
};