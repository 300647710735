import React, { useState } from 'react';
import * as PR from "primereact"
import { useNavigate } from 'react-router-dom';

import Aside from './Aside';
const Navbar = ({ Toggle }) => {
    const navigate = useNavigate()
    const [visible, setVisible] = useState(false);
    const visibleToggle = () => {
        setVisible(!visible)
    }
    return (
        <>
            <nav className="col-12 grid grid-nogutter p-0 shadow-2 navbar-container" >
                <div>
                    <div className="hidden lg:block py-2 pl-2">
                        <div className="flex align-items-center">
                            <PR.Button className="btn-secondary" icon="pi pi-bars" style={{ width: "40px", height: "40px", borderRadius: "50%" }} onClick={Toggle} text />
                            <span className="block pl-2"></span>
                        </div>
                    </div>
                    <div className="block lg:hidden">
                        <PR.Sidebar visible={visible} header={false} onHide={() => setVisible(false)} className="left-sidebar">
                            <Aside visibleToggle={visibleToggle} />
                        </PR.Sidebar>
                        <div className="p-2" >
                            <PR.Button icon="pi pi-bars" className="border-circle btn-secondary" style={{ width: "40px", height: "40px", borderRadius: "50%" }} onClick={() => setVisible(true)} text />
                        </div>
                    </div>
                </div>
                <div>
                    <PR.Button icon="pi pi-sign-out" className="border-circle btn-secondary" tooltip="Logout" tooltipOptions={{ position: 'bottom',style: { fontSize: '12px' }}} style={{ width: "40px", height: "40px", borderRadius: "50%", marginTop: "8px", marginRight: "20px" }} onClick={() => navigate("/")} />
                </div>
            </nav>
        </>
    );
}

export default Navbar;
