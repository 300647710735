import React from "react";

import { Badge } from 'primereact/badge';
export const links = [
    {
        title: 'Dashboard',
        icon: <i className="pi pi-angle-down" style={{ fontSize: '1.5rem' }}></i>,
        links: [
            {
                name: 'Employee',
                path: 'employee/employeelist',
                icon: <i className="pi pi-home"></i>,
            },
            {
                name: 'History',
                path: 'employee/history',
                icon: <i className="pi pi-user p-overlay-badge"></i>,
            },
        ],
    },
]
