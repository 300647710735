import { Routes, Route } from "react-router-dom";
import { useState } from "react"
import './App.css';
import { INITIAL_STATE as APP_INITIAL_STATE } from './components/app/reducer'
import { Login } from "./components/pages/Login/index"
import { Aside, Navbar, ShowAsideNav } from "./components/index";

import { History } from "./components/pages/History";
import EmployeeList from "./components/pages/Employee/EmployeeList";
import { StateProvider } from './context';
import reducers from "./reducers";

function App() {
	const [toggle, setToggle] = useState(false);
	const Toggle = () => {
		setToggle(!toggle);
	};
	const [visible, setVisible] = useState(false);
	const visibleToggle = () => {
		setVisible(!visible);
	};
	const initialState = {
        // auth: AUTH_INITIAL_STATE,
        app: APP_INITIAL_STATE
    }
	return (
		
		<>
		 <StateProvider initialState={initialState} reducer={reducers}>
			<Routes>
				<Route path="/" element={<Login />} />
				<Route path="/login" element={<Login />} />
			</Routes>
			<div className={toggle ? "layout-wrapper layout-static-inactive" : "layout-wrapper layout-static"} >
				<ShowAsideNav>
					<div className="hidden lg:block">
						<div className={toggle ? "layout-sidebar layout-sidebar-inactive " : "layout-sidebar "}>
							<Aside Toggle={Toggle} visibleToggle={visibleToggle} />
						</div>
					</div>
					<div className="layout-main-container">
						<Navbar Toggle={Toggle} />
						<div className="layout-main px-3">
							<Routes>
								<Route path="/employee/employeelist" element={<EmployeeList />} />
								<Route path="/employee/history" element={<History />} />
							</Routes>
						</div >
					</div >
				</ShowAsideNav >
			</div >
			</StateProvider>
		</>
	);
}

export default App;
