import React, { useState, useEffect, useRef } from "react";
import * as PR from "primereact"
import { DummyData } from "../../../data/DummyData";
import Search from "../../../components/Search";
import useHistory from "./hook";
import { useStateValue } from "../../../context";

const History = () => {
    const [{ app }, dispatch] = useStateValue()
    const { EmployeeHistory } = app || {}
    const toast = useRef(null);
    const [{employeeHistory},{}] = useHistory()
    const [employee, setEmployees] = useState([]);
    const [visibleRight, setVisibleRight] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    
    useEffect(() => {
        employeeHistory()
    }, []);

    const showMessage = () => {
        toast.current.show({ severity: "success", summary: "Successful" });
    };

    const OnRowSelect = () => {
        setVisibleRight(true)
    };  

    const Header = (
        <div className="grid grid-nogutter">
            <Search setGlobalFilter={setGlobalFilter} globalFilter={globalFilter} />
        </div>

    );

    const columns = [
        { field: 'emp_id', header: 'Employee ID', style: { height: "40px", width: '200px' } /*frozen className="font-bold"*/ },
        { field: 'firstname', header: 'First name', style: { height: "40px" } },
        { field: 'lastname', header: 'Last name', style: { height: "40px" } },
        { field: 'disable_date', header: 'Disable Date', style: { height: "40px" },
            body: (rowData) => {
                return (
                    <span>{new Date(rowData.disable_date).toLocaleString()}</span>
                );
            }
        },
        { field: 'api_response', header: 'Api Response', style: { height: "40px" } },
        // { field: 'api_call', header: 'Api Call', style: { height: "40px" } },
    ];
    return (
        <>

            <div className="card pt-0 mt-0 shadow-2 fadein animation-duration-300" >
                <PR.DataTable 
                value={EmployeeHistory} 
                header={Header} 
                size="small"
                paginator
                rows={25}
                rowsPerPageOptions={[10, 25, 50]}
                scrollable 
                scrollHeight="400px" 
                className="mt-4" 
                dataKey="id"
                metaKeySelection={false} 
                globalFilter={globalFilter}
                tableStyle={{ minWidth: "50rem", height: "100px" }}>

                    {/* <PR.Column field="eid" header="Employee ID" style={{ height: "40px", width: '200px' }} frozen className="font-bold"></PR.Column>
                    <PR.Column field="firstName" header="First name" style={{ height: "40px" }}></PR.Column>
                    <PR.Column field="lastName" header="Last name" style={{ height: "40px" }}></PR.Column>
                    <PR.Column field="email" header="Disable Date" style={{ height: "40px" }}></PR.Column>
                    <PR.Column field="email" header="Api Call" style={{ height: "40px" }}></PR.Column>
                    <PR.Column field="email" header="Api Response" style={{ height: "40px" }}></PR.Column> */}
                     {columns.map((column) => (
                        <PR.Column
                            key={column.field}
                            field={column.field}
                            header={column.header}
                            body={column.body}
                        // headerStyle={column.headerStyle}

                        // body={column.field === 'actions' ? renderActions : undefined}
                        />

                    ))}
                </PR.DataTable>
            </div>
        </>
    );
}

export default History;
