import { useRef, useState } from 'react'
import { useStateValue } from '../../../context'
import { _setObject } from '../../app/action'
import relayService from '../../axios/hook'
import axios from 'axios';

const useEmployee = () => {
    const [{ app }, dispatch] = useStateValue()
    // const { onedata } = app || {}
    const [Employee_list, setEmployee_list] = useState([])
    const [updatecustom, setupdatecustom] = useState([])
    const [visibleRight, setVisibleRight] = useState(false);
    const [visible, setVisible] = useState(false);

    const createEmployee = async (params) => {
        const response = await relayService({ url: '/employee/newEmployee', method: 'POST', data: params })
        if (response?.data) {
            const result = response?.data
            dispatch(_setObject({ newEmployee: result }))
            if (response?.data.success) {
                setVisibleRight(false);
            }
            getEmployeelist()
        }
    }

    const getEmployee = async (id) => {
        // const id = event.target.value
        const response = await relayService({ url: `/employee/getEmployee/${id}`, method: 'POST' })

        if (response?.data?.success && response?.data?.result) {
            const result = response?.data?.result

            dispatch(_setObject({ employee: result }))

        }

    }

    const getEmployeelist = async () => {
        const response = await relayService({ url: `/employee/Employeelist`, method: 'POST' })
        if (response?.data) {
            const result = response?.data?.result
            dispatch(_setObject({ Employee_list: result }))

        }
    }

    const updateEmployee = async (id, params) => {
        const response = await relayService({ url: `/employee/updateEmployee/${id}`, method: 'POST', data: params })
        if (response?.data?.success) {
            const result = response?.data?.message
            dispatch(_setObject({ updatedEmployee: result }))
            setVisibleRight(false);
            getEmployeelist()
        }
    }

    const ApiCall = async (e, rowData) => {
        if (!e.target.value) {
            const response = await relayService({ url: `/employee/ApiCall`, method: 'POST', data: {emp_status:e.target.value, employeeData:rowData} })
            console.log("api ress",response)
            if (response?.data) {
                const result = response?.data;
                dispatch(_setObject({ updatedEmployee: result }));
                setVisibleRight(false);
                getEmployeelist();
		setVisible(false);
            }


        }
    }
    return [{ createEmployee, getEmployeelist, getEmployee, updateEmployee, setVisibleRight, ApiCall, setVisible/*getidbyEmployee, getlistofEmployee, updateEmployee, deleteEmployee */ }, { visibleRight, visible /*onedata, updatecustom, Employee_list*/ }]
}

export default useEmployee
