import React, { useState } from 'react';
import * as PR from "primereact"
const Search = (props) => {
    console.log("props", props)
    const handleSearchInputChange = (e) => {
        props.setGlobalFilter(e.target.value); 
    };

    return (
        <>
            <div className="col-12 md:col">
                <div className="flex align-items-center gap-2">
                    <div className="w-full md:w-20rem my-2 md:my-0">
                        <span className="p-input-icon-left w-full">
                            <i className="pi pi-search" />
                            <PR.InputText placeholder="Search" className="w-full h-3rem" onChange={handleSearchInputChange} />
                        </span>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Search;
