import { useRef, useState } from 'react'
import { useStateValue } from '../../../context'
import { _setObject } from '../../app/action'
import relayService from '../../axios/hook'

const useHistory = () => {
    const [{ app },dispatch] = useStateValue()
    // const {  } = app || {}
    const employeeHistory = async (params) => {
        const response = await relayService({ url: '/employee/employeeHistory', method: 'POST' })
        if (response?.data?.success) {
            const result = response?.data?.EmployeeHistory
            dispatch(_setObject({ EmployeeHistory: result }))
        }
    }

    return [{ employeeHistory }, { /*onedata, updatecustom, Employee_list*/ }]
}

export default useHistory